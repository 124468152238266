<template>
<form @submit.prevent="">
    <div class="form-row" v-if="errors.length">
        <div class="col-12">
            <div class="alert alert-danger">
                <strong>خطا های زیر را رفع کنید : </strong>
                <ul>
                    <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
            </div>
        </div>
    </div>

    <slot v-if="!isMobile">
        <div class="form-row">
            <div class="col-12 col-md-12">
                <input type="text" placeholder="عنوان هشدار" class="widget-input input-no-bg input-lg input-set-border  w-100" v-model="alert.title">
            </div>
        </div>
        <div class="form-row form-row-grid-add" style="padding-bottom: 5px !important;">
            <div class="col-12 col-sm-6 mt-2">
                <a href="" @click.prevent="openDiagram()" class="w100-w tgju-btn tgju-btn-lg tgju-btn-danger"><i class="uil uil-apps"></i> انتخاب شاخص</a>
            </div>
            <div class="col-12 col-sm-6 mt-2" style="line-height: 1.5;">
                <label for="example-number-input5" class="modal-price-lable" id="price"> شاخص فعلی : {{ alert.asset.label }} </label><br>
                <label for="example-number-input5" class="modal-price-lable" id="price"> قیــمت فعلی : {{ market_price }} </label>
            </div>
        </div>
        <div class="form-row form-row-grid">
            <div class="col-12 col-sm-6 ">
                <label for="example-number-input">بیشترین</label>
                <input type="number" id="example-number-input" placeholder="بیشترین" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="alert.max">
            </div>
            <div class="col-12 col-sm-6 ">
                <label for="example-number-input1">کمترین</label>
                <input type="number" id="example-number-input1" placeholder="کمترین" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="alert.min">
            </div>
        </div>
        <div class="form-row">
            <div class="col-12">
                <label for="to">نوع نوسان</label>
                <div class="widget-select w-100 calendar-tags widget-select-lg">
                    <v-select2 v-model="alert.type" :clearable="false" label="value" :reduce="(option) => option.type" :options="tolerances">
                    </v-select2>
                </div>
            </div>
        </div>
        <div class="form-row checkbox-modal-list mt-2">
            <div class="col-12">
                <label for="send">کانال‌های دریافت هشدار</label>
            </div>
            <div class="col-12 col-sm-4">
                <input type="checkbox" class="custom-control-input" id="notification_email" v-model="alert.notification_email">
                <label class="custom-control-label" for="notification_email">ایمیل</label>
            </div>
            <div class="col-12 col-sm-4">
                <input type="checkbox" class="custom-control-input" id="notification_push" v-model="alert.notification_push">
                <label class="custom-control-label" for="notification_push">پوش نوتیفیکیشن</label>
            </div>
        </div>
    </slot>
    <slot v-if="isMobile">
        <div class="form-row">
            <v-text-field class="mobile-app-input mobile-app-input-modal" label="عنوان هشدار" id="dashboard-name" v-model="alert.title"></v-text-field>
        </div>
        <div class="form-row form-row-mobile-flex-modal" style="margin-top: 5px !important;margin-bottom: 2px;">
            <div class="col-12 col-md-12">
                <a href="" @click.prevent="openDiagram()" class="tgju-btn tgju-btn-lg tgju-btn-danger" style="max-width: 150px;margin-left: 10px;"><i class="uil uil-apps"></i> انتخاب شاخص</a>
                <div style="line-height: 1;padding-top: 5px;">
                    <label for="example-number-input5" class="modal-price-lable" id="price"> شاخص : {{ alert.asset.label }} </label><br>
                    <label for="example-number-input5" class="modal-price-lable" id="price"> قیـــمت : {{ market_price }} </label>
                </div>
            </div>
        </div>
        <div class="form-row form-row-mobile-flex-modal">
            <div class="col-12 col-md-12">
                <v-text-field type="number" id="example-number-input" class="mobile-app-input mobile-app-input-modal" style="margin-left:10px;" label="بیشترین" v-model="alert.max"></v-text-field>
                <v-text-field type="number" id="example-number-input1" class="mobile-app-input mobile-app-input-modal" label="کمترین" v-model="alert.min"></v-text-field>
            </div>
        </div>
        <div class="form-row">
            <v-select  v-model="alert.type" class="profile-mobile-view-select mobile-view-select-modal fit-h" :items="tolerances" item-text="value"  item-value="type" label="نوع نوسان" outlined></v-select>
        </div>
        <div class="form-row form-row-mobile-flex-modal" style="margin-bottom: 10px;">
            <div class="col-12 col-sm-12">
                <label for="send" style="margin-bottom: 3px;">کانال‌های دریافت هشدار</label>
            </div>
            <div style="display: inline-block;padding-left: 15px;">
                <input type="checkbox" class="custom-control-input" id="notification_email" v-model="alert.notification_email">
                <label class="custom-control-label" for="notification_email">ایمیل</label>
            </div>
            <div style="display: inline-block;padding-left: 15px;">
                <input type="checkbox" class="custom-control-input" id="notification_push" v-model="alert.notification_push">
                <label class="custom-control-label" for="notification_push">پوش نوتیفیکیشن</label>
            </div>
        </div>
    </slot>

    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveChanges()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary">
                <slot v-if="action == 'add'">
                    <i class="uil uil-plus-circle"></i> افزودن
                </slot>
                <slot v-if="action == 'edit'">
                    <i class="uil uil-save"></i> ذخیره
                </slot>
            </a>
        </div>
    </div>
</form>
</template>

<style lang="scss">
    #main-header {
        z-index: 100000000;
    }

    #bug-report-modal,
    .modal[role="dialog"] {
        z-index: 999999999;
    }

    #indicator-modal {
        z-index: 2147483647;
    }
</style>
 
<script>
// این کامپوننت برای ایجاد و ویرایش یک هشدار استفاده میشود
import vSelect2 from 'vue-select'
Vue.component('v-select2', vSelect2);

export default {
    name: 'AlertAdd',
    props: ['data'],
    components: {},
    data: function () {
        return {
            isMobile:false,
            errors: [],
            action: 'add',
            market_price: 0,
            alert: {
                title: undefined,
                asset: {
                    id: undefined,
                    symbol: undefined,
                    lable: undefined
                }, //market
                type: undefined,
                min: undefined,
                max: undefined,
                notification_email: false,
                notification_messenger: false,
                notification_push: false,
            },
            markets: [],
            tolerances: [{
                value: 'مقدار نوسان',
                type: 'amount',
            }, {
                value: 'مقدار ثابت',
                type: 'exact',
            }, {
                value: 'درصد نوسان',
                type: 'percentage',
            }, ]
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            this.loadData();
        }, { immediate: true });
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // تزریق و مقدار دهی اولیه به کامپوننت
        loadData() {
            this.action = this.data.action;
            this.alert.title = (this.action == 'add' ? '' : this.data.alert.title);
            this.alert.asset = (this.action == 'add' ? '' : JSON.parse(this.data.alert.asset));
            this.alert.type = (this.action == 'add' ? 'exact' : this.data.alert.type);
            this.alert.min = (this.action == 'add' ? 0 : this.data.alert.min);
            this.alert.max = (this.action == 'add' ? 0 : this.data.alert.max);
            this.alert.notification_email = (this.action == 'add' ? false : this.data.alert.notification_email);
            this.alert.notification_messenger = (this.action == 'add' ? false : this.data.alert.notification_messenger);
            this.alert.notification_push = (this.action == 'add' ? false : this.data.alert.notification_push);
            this.market_price = 0

            if (this.action == 'edit') {
                this.getPrice(this.alert.asset.symbol);
            }
        },
        // این متد برای ذخیره سازی اطلاعات استفاده میشود
        saveChanges() {
            this.errors = [];

            // بررسی فیلد های خالی
            if (!this.alert.title || !this.alert.asset || !this.alert.type || !this.alert.min || !this.alert.max) {
                this.errors.push('تمام فیلد ها الزامی می باشد');
            }

            // بررسی جهت انتخاب حداقل یک نحوه دریافت
            if (!this.alert.notification_push && !this.alert.notification_messenger && !this.alert.notification_email) {
                this.errors.push('حداقل یک نوع دریافت را انتخاب کنید')
            }

            if (!this.errors.length) {
                let event = (this.action == 'edit') ? 'editAlert' : 'addAlert';
                this.$parent.emitData(event, { alert: this.alert }, true);
            }
        },
        // این متد برای دریافت قیمت شاخص از سرور استفاده می شود
        getPrice(symbol) {
            // ارسال درخواست ای پی آی
            this.$helpers.makeRequest('GET', '/alert/market-price/' + symbol).then(api_response => {
                this.$helpers.api_alert(api_response);
                this.market_price = api_response.data.response.price;
            });
        },
        // این متد وظیفه باز کردن مودال شاخصیاب را دارد
        openDiagram() {
            $('html').addClass('open-alert-modal');
            let callbackEvent = 'jQuery.hColumns' + new Date().getTime();
            localStorage.setItem('jQuery_hColumns_event', callbackEvent);

            this.$root.$on(callbackEvent, (data) => {
                this.$root.$off(callbackEvent);
                this.alert.asset = data;
                this.getPrice(data.symbol);
            });

            $('#indicator-modal').show();
            $('#indicator-modal #columns2').html('');
            this.$helpers.diagrams_render();
        },
    },
}
</script>
